import React from "react"
import bgImage from "../assets/mccalla-coaching-header-background.webp"
import michaelAsTeam from "../assets/michael-mccalla-team-cycling.webp"
import markus from "../assets/markus-spiske-unsplash.jpg"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import PageHeader from "../components/PageHeader"
import { Container } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import CtaContact from "../components/CTAContact"

// const LIST_1 = [
//   "Goal development",
//   "Event selection",
//   "Completely custom daily workouts",
//   "High intensity training (including training with power meters)",
//   "Daily and pre/post event nutritional strategies",
//   "Peaking/Tapering for events",
//   "Aerodynamics",
// ]
const LIST_1 = [
  "Free initial consultation/interview",
  "No sign-up fee",
  "No “minimum” contract, pay for as little as one month",
  "Comprehensive diagnostic session/interview",
  "Weekly coach initiated phone and email contact",
  "Unlimited coach availability through voice messages/email",
  "Completely custom weekly training plans",
  "Event preparation, including equipment/clothing selection, nutrition strategies and logistics",
  "Training plans updated as needed",
  "Travel with athlete to targeted events (availability limited, additional fee)"
]

// const LIST_2 = [
//   "Bicycle and other equipment selection for specific disciplines/events",
//   "Event tactics/pacing strategies",
//   "Technical skills, including descending, turning, climbing, braking, shifting and sprinting techniques",
//   "Legal ergogenic aids and nutritional supplements",
//   "Other training modalities (strength/cross training, heat/altitude adaptation)"
// ]

const LIST_2 = [
  "Free initial consultation/interview",
  "No sign-up fee",
  "Comprehensive team analysis",
  "Custom weekly training programs for every level of athlete",
  "Event preparation, including equipment/clothing selection, nutrition strategies and logistics",
  "Bike handling skills training",
  "Weekly coach initiated phone and email contact",
  "Unlimited coach availability through voice messages/email",
  "Training plans updated as needed",
  "Travel with teams to targeted events (availability limited, additional fee)"
]

const renderList = list => (
  <ul>
    {list.map(listItem => <li><Typography variant="body1">{listItem}</Typography></li>)}
  </ul>
)

const IndexPage = () => (
  <>
    <SEO title="Services"
         description="Michael will help you achieve your goals in the disciplines of cross-country and marathon mountain biking, gravel, cyclocross and road events."/>
    <PageHeader backgroundImage={bgImage} backgroundImageHeight={30} backgroundTextTopPosition={"50%"}>
      <Typography variant="h1">Services</Typography>
    </PageHeader>
    <Container>
      <Box marginTop={8} marginBottom={4}>
        <Typography variant="h6" component="p" gutterBottom>
          Michael will guide you to your goals in the disciplines of cross-country and marathon mountain biking,
          gravel, cyclocross and road events.
        </Typography>
      </Box>
      <Box marginTop={0} marginBottom={8}>
        <Typography variant="h2" gutterBottom>
          Personalized Coaching $ 325/month
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            {renderList(LIST_1)}
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={markus} alt="test" style={{ maxWidth: "100%" }}/>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={8} marginBottom={8}>
        <Typography variant="h2" gutterBottom>
          Team/Club Coaching Programs (USAC/NICA/NCAA) <br/>starting at $ 500/month, 3 month minimum commitment
        </Typography>
        <Typography variant="body1" gutterBottom>
          Michael will prepare your cycling team for all aspects of competition, as well as planning your own events,
          easier and more successful.
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            {renderList(LIST_2)}
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={michaelAsTeam} alt="test" style={{ maxWidth: "100%" }}/>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={6} marginBottom={8}>
        <CtaContact/>
      </Box>
    </Container>
  </>
)

export default IndexPage


/*

<Box marginTop={8} marginBottom={8}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={serviceImage1}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h3">
                      Lizard
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica <br/><br/>
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={serviceImage2}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h3">
                      Speed
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica <br/><br/>
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={serviceImage3}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h3">
                      Endurance
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica <br/><br/>
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={serviceImage4}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h3">
                      Fun
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica <br/><br/>
                      Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                      across all continents except Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>

 */
